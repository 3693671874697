import { partial } from 'lodash';
import React, { ReactElement, useMemo } from 'react';
import styled, { StyledComponent } from '@emotion/styled';

import { withDisplayName } from './helpers';
import Replaceable from '~/components/higher-order/ReplaceableComponents';

export interface StyleableStyles {
  (data: Record<string, React.CSSProperties>): React.CSSProperties;
}

export interface StyleableProps
  extends Record<string, StyleableStyles | undefined> {}

export type GetStyleFn = (
  key: string,
  childProps: StyleableProps
) => React.CSSProperties | undefined;

const getStyles =
  (defaultStyles: StyleableProps, stylesProp: StyleableProps): GetStyleFn =>
  (key, childProps) => {
    const defaultStyle = defaultStyles[key];
    const customStyle = stylesProp[key];

    const data = {
      props: childProps,
    };

    const base = defaultStyle?.(data) || {};
    if (defaultStyle === customStyle) {
      return base;
    }

    return customStyle?.({
      base,
      ...data,
    });
  };

type StylesInternalProps = StyleableProps & {
  getStyles: GetStyleFn;
};

function getStylesInternal(
  key: string,
  { getStyles, ...props }: StylesInternalProps
) {
  return getStyles(key, props as StyleableProps);
}

export function createStyledComponent(
  component: StyledComponent<React.ComponentType, ReactElement>,
  key: string
) {
  return styled(component)(partial(getStylesInternal, key));
}

export default (defaultStyles: StyleableProps) =>
  withDisplayName((Wrapped) => {
    function Styleable(props: { styles: StyleableProps }) {
      return (
        <Wrapped
          {...props}
          getStyles={useMemo(
            () => getStyles(defaultStyles, props.styles),
            [props]
          )}
        />
      );
    }

    Styleable.displayName = 'Styleable';

    return Replaceable(defaultStyles, 'styles')(Styleable);
  });
