import { get, set } from 'lodash';

// these are helpers for development

// support method
export const getWrappedComponentKey = (Component) => Component.name + 'Wrapped';

// generates a display name for the wrapper component
// by default if you have multiple wrappers the display name will
// reference the underlying wrapped component
export const getWrapperDisplayName = (Component, Wrapped) => {
  const componentKey = getWrappedComponentKey(Component);
  const wrappedName =
    get(Wrapped, componentKey) || Wrapped.displayName || Wrapped.name;

  return `${wrappedName}__${Component.name}`;
};

// injects display names into wrapper components for use in development
// will short circuit in production
export const withDisplayName = (
  getHigherOrderComponent,
  getDisplayName = getWrapperDisplayName
) => (Wrapped) => {
  const Component = getHigherOrderComponent(Wrapped);

  if (process.env.NODE_ENV !== 'production') {
    const componentKey = getWrappedComponentKey(Component);
    set(
      Component,
      componentKey,
      get(Wrapped, componentKey) || Wrapped.displayName || Wrapped.name
    );

    Component.displayName = getDisplayName(Component, Wrapped);
  }

  return Component;
};
