import styled from '@emotion/styled';

export const Heading = styled.h1``;
Heading.defaultProps = {
  className: 'heading',
};

export const SubHeading = styled.h2``;
SubHeading.defaultProps = {
  className: 'sub-heading',
};

export const BodyCopy = styled.p``;
BodyCopy.defaultProps = {
  className: 'body-copy',
};

export const HeroTitle = styled.h1``;
HeroTitle.defaultProps = {
  className: 'hero-title',
};

export const BannerCopy = styled.p``;
BannerCopy.defaultProps = {
  className: 'banner-copy',
};

export const BannerSubtitle = styled.h3``;
BannerSubtitle.defaultProps = {
  className: 'card-title',
};

export const CopyHeading = styled.h3``;
CopyHeading.defaultProps = {
  className: 'copy-heading',
};

export const CardHeading = styled.h4``;
CardHeading.defaultProps = {
  className: 'card-heading',
};

export default {
  Heading,
  SubHeading,
  BodyCopy,
  HeroTitle,
  BannerCopy,
  BannerSubtitle,
  CopyHeading,
  CardHeading,
};
