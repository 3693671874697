import React from 'react';
import { Button } from 'react-bootstrap';

import { createStyledComponent } from '~/App/Components/HOC/Styleable';

export const styles = () => ({});

const BaseButton = ({ getStyles, ...props }) => <Button {...props} />;

const CancelButton = createStyledComponent(BaseButton, 'cancelButton');

export default CancelButton;
