import React from 'react';

import { assetUrl } from '~/helpers';
import { alertAsync } from '~/App/Components/Modals/Alert';

export function hideIntercomPopup() {
  if (typeof window.Intercom === 'function') {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  } else {
    window.intercomSettings = {
      ...window.intercomSettings,
      hide_default_launcher: true,
    };
  }
}

export default function onHelpClick(evt: { preventDefault(): void }) {
  evt.preventDefault();
  if ('Intercom' in window) {
    Intercom('showNewMessage');
  } else {
    alertAsync(
      <>
        <h1>Help Not Currently Available</h1>
        <img src={assetUrl('assets/images/joro/face_surprised.png')} />
        <h3>
          Sorry, interactive help is not available right now. Urgent?{' '}
          <a href='mailto:joro@bonjoro.com'>Email us</a> at joro@bonjoro.com
        </h3>
      </>
    );
  }
}
