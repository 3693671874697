import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useAnalytics from '~/react-hooks/use-analytics';

function TrackPageViews() {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.page(
      {},
      {
        plugins: {
          amplitude: false,
        },
      }
    );
  }, [location.pathname]);

  return null;
}

export default TrackPageViews;
