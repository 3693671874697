const config = {
  FILE_UPLOAD_MAX_SIZE_MB: Number(process.env.FILE_UPLOAD_MAX_SIZE_MB || 100),
};

// Common configuration provided on defaultOptions
/** @type { import('react-query/types/core').DefaultOptions } */
export const reactQueryDefaultOptions = {
  queries: {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('react-query useQuery error', error);
    },
    useErrorBoundary: false,
    retry: 1,
    staleTime: 1000 * 300,
    cacheTime: 1000 * 300,
  },
  mutations: {
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error('react-query useMutation error', error);
    },
    // Set to false so validation error does not display an error page
    useErrorBoundary: false,
    retry: 1,
  },
};

export default {
  ...config,
  FILE_UPLOAD_MAX_SIZE_B: config.FILE_UPLOAD_MAX_SIZE_MB * 1000000,
};
