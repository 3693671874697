import React from 'react';
import ReactModal from 'react-modal';
import { Global, css } from '@emotion/react';

import { TRANSITION_TIME_MS } from './Constants';
import { createStyledComponent } from '~/App/Components/HOC/Styleable';

const StyledReactModal = createStyledComponent(ReactModal, 'content');

export const contentStyles = () => ({
  position: 'relative',
  background: 'none',
  borderRadius: '20px',
  outline: 'none',
  padding: '20px',
  maxWidth: '660px',
  margin: '50px auto',
  border: 'none',
});

export const overlayStyles = () => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 9999,
  fontSize: '14px',
  overflowY: 'scroll',
});

const ModalImpl = function (props: ReactModal.Props) {
  return (
    <React.Fragment>
      <Global
        styles={css`
          .ReactModal__Overlay {
            opacity: 0;
            transition: opacity ${TRANSITION_TIME_MS}ms ease-in-out;
          }

          .ReactModal__Overlay--after-open {
            opacity: 1;
          }

          .ReactModal__Overlay--before-close {
            opacity: 0;
          }

          @media (max-width: 768px) {
            .ReactModal__Content {
              width: 100%;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              padding: 0;
            }
          }
        `}
      />
      <StyledReactModal {...props} />
    </React.Fragment>
  );
};

export default ModalImpl;
