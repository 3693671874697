import * as Ladda from 'ladda';

import route from '../../utils/Route';
import Purser from '../../utils/Purser';
import Controller from '../../utils/Controller';
import { Devices, getDevice } from '../../utils/agent';

declare global {
  interface Window {
    opera?: string;
  }
}

async function getPasswordValidator() {
  return (await import('../AuthRegister')).default;
}

async function initPasswordValidator() {
  (await getPasswordValidator())();
}

function switchSignupSource() {
  if (window.document.body.scrollWidth < 768) {
    const os = getDevice();

    if ([Devices.Android, Devices.Ios].includes(os)) {
      $('input[name="signup_source"]').attr('value', 'mweb');
    }
  }
}

function switchAccordion() {
  $('a.heading').click((e) => {
    e.preventDefault();
    $('.features').find('.feature').removeClass('in');
    $(e.target).closest('.feature').addClass('in');
  });
}

export default class PublicController extends Controller {
  constructor(public $navbarHelpLink: JQuery<HTMLElement> = $('#nav-help')) {
    super();
    Purser();
  }

  @route('uses{?query}')
  @route('uses')
  useCase() {
    const videoPlayers =
      document.querySelectorAll<HTMLDivElement>('.use-video-player');

    videoPlayers.forEach((pauseDiv) => {
      const videoPlayer = pauseDiv.querySelector<HTMLVideoElement>('video');

      if (!videoPlayer) return;

      pauseDiv.addEventListener('click', () => {
        if (videoPlayer.paused) {
          videoPlayer.play();
          pauseDiv.classList.add('playing');
        } else {
          pauseDiv.classList.remove('playing');
          videoPlayer.pause();
        }
      });
    });
  }

  @route('/auth/invites/{token}/register')
  async registerViaInvite() {
    await initPasswordValidator();
    switchSignupSource();
  }

  @route('password/reset')
  reset() {
    Ladda.bind('button[type=submit]');
  }

  @route('password/reset/{token}')
  async resetToken() {
    Ladda.bind('button[type=submit]');
    await initPasswordValidator();
  }

  @route('auth/invites/{token}/accept')
  accept() {
    Ladda.bind('button[type=submit]');
  }

  @route('auth/login')
  login() {
    Ladda.bind('button[type=submit]');
  }

  @route('about')
  async about() {
    const About = (await import('../About')).default;

    About();
  }

  @route('auth/register:?details:')
  @route('auth/register/:?details:')
  @route('auth/invites:?details:')
  @route('auth/invites/:?details:')
  async auth(details?: Record<string, string>) {
    await initPasswordValidator();

    Ladda.bind('button[type=submit]');
    switchSignupSource();

    $('#btn-signup-email').on('click', () => {
      $('.split-form').addClass('form-email-open');
    });

    $('input:-webkit-autofill').each(function () {
      const text = $(this).val() ?? '';
      const name = $(this).attr('name');
      $(this).after(this.outerHTML).remove();
      $('input[name=' + name + ']').val(text);
    });

    if (details) {
      const firstNameInput =
        document.querySelector<HTMLInputElement>('#first-name-input');
      if (firstNameInput) {
        firstNameInput.value = details.first_name ?? '';
      }

      const lastNameInput =
        document.querySelector<HTMLInputElement>('#last-name-input');
      if (lastNameInput) {
        lastNameInput.value = details.last_name ?? '';
      }
    }
    $('[data-toggle="tooltip"]').tooltip();
  }

  @route('pricing')
  pricing() {
    $('#tab-annual a').click((e) => {
      e.preventDefault();
      $(e.currentTarget).tab('show');
    });
    $('#tab-monthly a').click((e) => {
      e.preventDefault();
      $(e.currentTarget).tab('show');
    });

    $('.feature-link').on('click', (event) => {
      event.preventDefault();
      const hash = $(event.currentTarget).attr('href');
      const scroll = $(hash).offset().top - $('nav').height();
      $('html, body').animate(
        {
          scrollTop: scroll,
        },
        500
      );
    });

    $('[data-toggle="popover"]').popover({
      trigger: 'hover',
    });

    $('.pricing-button-container button.btn').on('click', () => {
      window.location = '/auth/register';
    });

    $('.plan-item-cta button.btn').on('click', () => {
      window.location = '/auth/register';
    });
  }

  @route('features{?query}')
  @route('features')
  feature() {
    const overlay = $('.story-modal-overlay');

    function fillHtmlThenShowModal(html: string) {
      $('.customer-story-modal .modal-iframe').html(html);
      overlay.addClass('in');
    }

    function clearHtmlThenHideModal() {
      overlay.removeClass('in');
      $('.customer-story-modal .modal-iframe').html('');
    }

    overlay.on('click', (evt) => {
      if (evt.target !== overlay[0]) return;
      clearHtmlThenHideModal();
    });

    $('.customer-story-modal .modal-close').on('click', () => {
      clearHtmlThenHideModal();
    });

    $('.feature-testimonial').on('click', function () {
      const html = $(this).find('.video-embed-code').html();
      fillHtmlThenShowModal(html);
    });

    switchAccordion();
  }

  @route(/\/(features|about|privacy-policy|terms-and-conditions)/i)
  async navbar() {
    const IntercomPopup = (await import('../../utils/IntercomPopup')).default;

    $(this.$navbarHelpLink).on('click', IntercomPopup);
  }

  @route('screen-recorder/:?query:')
  productPage() {
    switchAccordion();
  }
}
