import { pick } from 'lodash';
import qs from 'query-string';
import localForage from 'localforage';

export interface Attributes {
  first_website_visit: string;
  referrer: string;
  browser_timezone: number;
  browser_language: string;
  landing_page: string;
  screen_height: number;
  screen_width: number;
  last_active?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_name?: string;
  utm_term?: string;
  utm_campaign?: string;
  utm_content?: string;
  count?: number;
}

type QueryStringObject = Pick<
  Attributes,
  | 'utm_campaign'
  | 'utm_content'
  | 'utm_medium'
  | 'utm_name'
  | 'utm_source'
  | 'utm_term'
>;

declare global {
  interface Window {
    purser?: Purser;
  }
}

const PURSER_KEY = 'bonjoro_visitor';

export class Purser {
  private _queryString: QueryStringObject | null = null;
  private _attributes: Attributes | null = null;

  async fetch() {
    if (this._attributes == null) {
      this._attributes = await localForage.getItem<Attributes>(PURSER_KEY);
    }

    return this._attributes;
  }

  async destroy() {
    await localForage.removeItem(PURSER_KEY);

    this._attributes = null;
  }

  async create(): Promise<Attributes> {
    const now = new Date();
    const nowISO = now.toISOString();

    const attributes: Attributes = {
      first_website_visit: nowISO,
      referrer: document.referrer.length ? document.referrer : 'direct',
      browser_timezone: now.getTimezoneOffset(),
      browser_language: window.navigator.language,
      landing_page: window.location.origin + window.location.pathname,
      screen_height: window.screen.height,
      screen_width: window.screen.width,
      last_active: nowISO,
      count: 1,
      ...this.queryString,
    };

    await localForage.setItem(PURSER_KEY, attributes);

    return (this._attributes = attributes);
  }

  async update(): Promise<Attributes> {
    const attributes = (await this.fetch()) ?? (await this.create());

    let newAttributes: Attributes;
    if (this.isNewSession()) {
      newAttributes = {
        ...attributes,
        last_active: new Date().toISOString(),
        utm_campaign: this.currentUtmCampaign,
        count: (attributes.count ?? 1) + 1,
      };
    } else {
      // Update last_active to refresh session timer
      newAttributes = {
        ...attributes,
        last_active: new Date().toISOString(),
      };
    }

    await localForage.setItem(PURSER_KEY, newAttributes);
    return (this._attributes = newAttributes);
  }

  async updateOrCreate(): Promise<Attributes> {
    const attributes = await this.fetch();

    if (attributes == null) {
      await this.create();
    } else {
      await this.update();
    }

    return this._attributes as Attributes;
  }

  private get queryString() {
    if (!this._queryString) {
      this._queryString = pick<QueryStringObject>(qs.parse(location.search), [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_name',
        'utm_content',
        'utm_term',
      ]);
    }

    return this._queryString;
  }

  private get currentUtmCampaign() {
    return this.queryString.utm_campaign;
  }

  private isNewSession(): boolean {
    const now = new Date();
    const lastActive = this._attributes?.last_active
      ? new Date(this._attributes.last_active)
      : new Date();

    return [
      (now.getTime() - lastActive.getTime()) / 1000 / 60 > 30,
      now.toDateString() !== lastActive.toDateString(),
      this.currentUtmCampaign &&
        this.currentUtmCampaign !== this._attributes?.utm_campaign,
    ].some((b) => b);
  }
}

let purser: Purser | null = null;

async function getPurser() {
  if (!purser) {
    purser = new Purser();

    await purser.updateOrCreate();
  }

  return purser;
}

export default async () => {
  return (window.purser = window.purser ?? (await getPurser()));
};
