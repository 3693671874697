import crossroads from 'crossroads';

import Controller from './Controller';

export default (pattern: string | RegExp) => <
  T extends Controller,
  K extends keyof T
>(
  target: T,
  propertyKey: K
) => {
  crossroads.addRoute(pattern, (...args: unknown[]) => {
    $(() => {
      // eslint-disable-next-line
      const ControllerClass: new () => T = (target as any).constructor;

      const controller = new ControllerClass();

      // eslint-disable-next-line
      (controller[propertyKey] as any).call(controller, ...args);
    });
  });
};
