import Controller from '../../utils/Controller';
import route from '../../utils/Route';

class StoriesController extends Controller {
  @route('uses/{slug}')
  @route('uses/{slug}{?query}')
  stories(slug, query) {
    const overlay = $('.story-modal-overlay');

    function fillHtmlThenShowModal(html) {
      $('.customer-story-modal .modal-iframe').html(html);
      overlay.addClass('in');
    }

    function clearHtmlThenHideModal() {
      overlay.removeClass('in');
      $('.customer-story-modal .modal-iframe').html('');
    }

    overlay.on('click', (evt) => {
      if (evt.target !== overlay[0]) return;
      clearHtmlThenHideModal();
    });

    $('.customer-story-modal .modal-close').on('click', (evt) => {
      clearHtmlThenHideModal();
    });

    $('.story-header-image').on('click', function () {
      const html = $(this).find('.video-embed-code').html();
      fillHtmlThenShowModal(html);
    });

    $('.case-study-item').on('click', function () {
      const html = $(this).find('.video-embed-code').text();
      fillHtmlThenShowModal(html);
    });
  }
}
