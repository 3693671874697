export enum Devices {
  WindowsPhone = 'Windows Phone',
  Ios = 'iOS',
  Android = 'Android',
  Desktop = 'Desktop',
}

export function isSmallScreen() {
  return window.screen?.width <= 768;
}

export const isMobileDevice = (): boolean => {
  return (
    !!/Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|windows phone/i.test(
      navigator.userAgent || ''
    ) ||
    (navigator.userAgent.includes('Mac') && document.ontouchend != null)
  );
};

export const isAndroid = (): boolean => {
  return /Android/i.test(navigator.userAgent);
};

export const isIos = (): boolean => {
  //https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  return (
    /iPhone|iPad|iPod/i.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};

export const isWindowsPhone = (): boolean => {
  return /windows phone/i.test(navigator.userAgent);
};

export function getDevice(): Devices {
  if (isAndroid()) {
    return Devices.Android;
  }

  if (isIos()) {
    return Devices.Ios;
  }

  if (isWindowsPhone()) {
    return Devices.WindowsPhone;
  }

  return Devices.Desktop;
}

export function isChromeBrowser(): boolean {
  return (
    navigator.userAgent.includes('Chrome') &&
    navigator.vendor.includes('Google Inc')
  );
}
