import cx from 'classnames/dedupe';
import { flow, partial } from 'lodash';
import React, { memo, useLayoutEffect } from 'react';
import styled from '@emotion/styled';
import FontAwesome from 'react-fontawesome';

import { TRANSITION_TIME_MS } from './Modal/Constants';
import Styleable, { StyleableProps, StyleableStyles } from './HOC/Styleable';
import Panel, { styles as panelStyles } from './Modal/Panel';
import Actions, { styles as actionsStyles } from './Modal/Actions';
import { styles as cancelButtonStyles } from './Modal/CancelButton';
import { styles as confirmButtonStyles } from './Modal/ConfirmButton';
import Replaceable from '~/components/higher-order/ReplaceableComponents';
import ModalImpl, { contentStyles, overlayStyles } from './Modal/ModalImpl';

export const components = {
  Panel,
  Actions,
  ModalImpl,
};

export const styles: StyleableProps = {
  panel: panelStyles as StyleableStyles,
  actions: actionsStyles as StyleableStyles,
  overlay: overlayStyles as StyleableStyles,
  content: contentStyles as StyleableStyles,
  confirmButton: confirmButtonStyles,
  cancelButton: cancelButtonStyles,
};

type CommonProps = Pick<ModalProps, 'getStyles' | 'isOpen'>;
type Components = Record<
  'Panel' | 'Actions' | 'ModalImpl',
  (args: Record<string, unknown>) => React.ReactElement
>;

function getCommonProps(props: CommonProps) {
  const { getStyles, isOpen } = props;

  return {
    getStyles,
    isOpen,
  };
}

function getModalProps(props: ModalProps, commonProps: CommonProps) {
  const {
    isOpen,
    onRequestClose,
    onAfterOpen,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc = true,
    overlayRef,
    contentRef,
    getStyles,
    className,
  } = props;

  return {
    getStyles,
    className,
    ariaHideApp: false,
    closeTimeoutMS: TRANSITION_TIME_MS,
    isOpen,
    onRequestClose,
    onAfterOpen,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    overlayRef,
    contentRef,
    style: {
      overlay: getStyles?.('overlay', commonProps),
    },
  };
}

function getActionsProps(props: ModalProps, commonProps: CommonProps) {
  const {
    actionsClassName,
    confirmButtonText,
    confirmButtonStyle = 'primary',
    confirmButtonDisabled,
    cancelButtonText,
    cancelButtonStyle = 'secondary',
    onRequestClose,
    onConfirmClick,
    isLoading,
  } = props;

  return {
    className: actionsClassName,
    confirmButtonText,
    confirmButtonStyle,
    confirmButtonDisabled,
    cancelButtonText,
    cancelButtonStyle,
    onConfirmClick,
    onRequestClose,
    isLoading,
    ...commonProps,
  };
}

export type ModalProps = {
  isOpen: boolean;
  onConfirmClick?: () => void;
  onRequestClose: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  onAfterOpen?: () => void;
  panelRef?: React.MutableRefObject<HTMLElement | undefined>;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  overlayRef?: () => void;
  contentRef?: () => void;
  confirmButtonText?: string;
  confirmButtonStyle?: string;
  confirmButtonDisabled?: boolean;
  cancelButtonText?: string;
  cancelButtonStyle?: string;
  children?: React.ReactNode;
  components?: Components;
  hideActions?: boolean;
  isLoading?: boolean;
  getStyles?: <P>(key: string, props: P) => Record<string, unknown>;
  className?: string;
  actionsClassName?: string;
  showCloseButton?: boolean;
};

const Close = styled(FontAwesome)`
  z-index: 200;
  opacity: 50%;
  cursor: pointer;
  font-size: 21px;
  display: flex;
  width: fit-content;
  margin-top: -18px;
  margin-left: auto;
`;
Close.defaultProps = {
  name: 'times',
};

function Modal(props: ModalProps) {
  const { children, components, hideActions, onRequestClose, showCloseButton } =
    props;

  const { Panel, Actions, ModalImpl } = components as Components;

  const setModalOpen = (isOpen: boolean) => {
    document.body.className = cx(document.body.className, {
      'modal-open': isOpen,
    });
  };

  useLayoutEffect(() => {
    setTimeout(
      partial(setModalOpen, props.isOpen),
      props.isOpen ? 0 : TRANSITION_TIME_MS
    );
  }, [props.isOpen]);

  const commonProps = getCommonProps(props);
  const modalProps = getModalProps(props, commonProps);

  return (
    <ModalImpl {...modalProps}>
      <Panel ref={props.panelRef} {...commonProps} className={'panel'}>
        {showCloseButton && <Close onClick={onRequestClose} />}
        {children}
        {hideActions || <Actions {...getActionsProps(props, commonProps)} />}
      </Panel>
    </ModalImpl>
  );
}

export default flow(
  memo,
  Styleable(styles),
  Replaceable(components)
)(Modal) as (props: ModalProps) => JSX.Element;
