import React from 'react';
import LaddaButton from 'react-ladda';
import { Button } from 'react-bootstrap';

import { createStyledComponent } from '~/App/Components/HOC/Styleable';

export const styles = () => ({
  marginRight: '5px',
});

const BaseButton = ({ getStyles, ...props }) => (
  <Button componentClass={LaddaButton} bsStyle='primary' {...props} />
);

const ConfirmButton = createStyledComponent(BaseButton, 'confirmButton');

export default ConfirmButton;
