import Analytics, { AnalyticsInstance, AnalyticsPlugin } from 'analytics';

import toBoolean from 'to-boolean';
import segment from '@analytics/segment';
import amplitude from '@analytics/amplitude';
import tapfiliate from 'analytics-plugin-tapfiliate';
import activecampaign from 'analytics-plugin-activecampaign';
import profitwell, {
  ProfitwellIdentifyMode,
} from 'analytics-plugin-profitwell';
import intercom from '@analytics/intercom';
import linkedin from 'analytics-plugin-linkedin';
import googleTagManager from '@analytics/google-tag-manager';
import bonjoro from './plugins/BonjoroAnalyticsPlugin';
import Route from 'route-parser';
import crazyEgg from './plugins/CrazyEggPlugin';

export const ANALYTICS_NAME = '__bonjoroAnalytics__';
export const DISABLED_TRACKING_ROUTES = ['/pe']; //disable tracking on these routes.

function getPlugins(): AnalyticsPlugin[] {
  const shouldTrackOnEnv = ['production', 'staging'].includes(
    process.env.APP_ENV as string
  );
  const shouldActivateTrackingOnPage =
    typeof DISABLED_TRACKING_ROUTES.find((route) =>
      new RegExp(`${route}`).test(location.pathname)
    ) === 'undefined';

  if (!shouldTrackOnEnv) {
    return [];
  }

  let plugins: AnalyticsPlugin[] = [
    googleTagManager({
      containerId: process.env.GTM_CONTAINER_ID!,
    }),
  ];

  if (!shouldActivateTrackingOnPage) {
    return plugins;
  }

  plugins = [
    segment({
      writeKey: process.env.SEGMENT_CLIENT_KEY as string,
      syncAnonymousId: true,
      disableAnonymousTraffic: true,
      integrations: {
        Amplitude: false,
        'Google Analytics': false,
        'Google Tag Manager': false,
        Intercom: false,
      },
    }),
    tapfiliate({
      tapfiliateId: process.env.TAPFILIATE_ID as string,
    }),
    activecampaign({
      accountId: process.env.ACTIVE_CAMPAIGN_ACCOUNT_ID as string,
    }),
    profitwell({
      publicToken: process.env.PROFITWELL_AUTH as string,
      identifyMode: ProfitwellIdentifyMode.Custom,
      getCustomId(traits: { customerId: string }) {
        return traits.customerId;
      },
    }),
    amplitude({
      apiKey: process.env.AMPLITUDE_API_KEY as string,
    }),
    linkedin({
      partnerId: process.env.LINKEDIN_PARTNER_ID!,
    }),
    intercom({
      appId: window.Bonjoro.intercom.appId,
      userHash: window.Bonjoro.intercom.userHash,
    }),
    ...plugins,
  ];

  if (window.Bonjoro.bonjoroJs.enabled) {
    plugins.push(
      bonjoro({
        appId: window.Bonjoro.bonjoroJs.appId,
        userHash: window.Bonjoro.bonjoroJs.userHash,
        endpoint: window.Bonjoro.bonjoroJs.endpoint,
        customScriptSource: window.Bonjoro.bonjoroJs.customScriptSource,
      })
    );
  }

  if (window.Bonjoro.crazyEgg.enabled) {
    plugins.push(
      crazyEgg({
        customScriptSource: window.Bonjoro.crazyEgg.customScriptSource,
      })
    );
  }

  // Implement page rules for the plugins
  window.Bonjoro.analyticsConfig?.pageRules?.forEach((pageRule) => {
    if (checkMatchedPaths(pageRule.paths)) {
      if (typeof pageRule.disabled == 'boolean' && pageRule.disabled) {
        plugins = [];
      } else if (typeof pageRule.disabled == 'object') {
        plugins = plugins.filter(
          (plugin) =>
            !(pageRule.disabled as string[]).includes(plugin.name as string)
        );
      }
    }
  });

  return plugins;
}

function checkMatchedPaths(paths: string[]) {
  const matched = paths.filter((path) => {
    // convert path to URL so we can get it's individual parts
    const current = new URL(window.location.origin + path);

    let routeParam = current.pathname;

    // contains a splat without a name
    // give it a name of "any"
    // @see https://github.com/rcs/route-parser
    if (routeParam.endsWith('*')) {
      routeParam += 'any';
    }

    // create route from pathname and hash
    const route = new Route(routeParam);

    // origin and route must match
    return (
      window.location.origin === current.origin &&
      route.match(window.location.pathname)
    );
  });

  return matched.length > 0;
}

function initAnalytics() {
  return Analytics({
    app: 'bonjoro',
    debug: toBoolean((process.env.APP_DEBUG ?? false) as string),
    plugins: getPlugins(),
  });
}

function getAnalytics(): AnalyticsInstance {
  if (!window[ANALYTICS_NAME]) {
    window[ANALYTICS_NAME] = initAnalytics();

    /* eslint-disable @typescript-eslint/no-unsafe-return */
    window.track = async (event: string, traits: Record<string, unknown>) =>
      await window[ANALYTICS_NAME]!.track(event, traits);
    /* eslint-enable @typescript-eslint/no-unsafe-return */
  }

  return window[ANALYTICS_NAME];
}

export default getAnalytics();
