import React, { lazy } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import LazyRoute from '~/components/Router/LazyRoute';
import TrackPageViews from '~/App/Components/Shared/TrackPageViews';
import { Blog } from './Modules/Blog';

const Teams = lazy(() => import('~/Public/Modules/Teams'));
const LargeCarousel = lazy(
  () => import('~/Public/Modules/Registration/LargeCarousel')
);
const SimpleCarousel = lazy(
  () => import('~/Public/Modules/Registration/SimpleCarousel')
);
const AppSumoRegistration = lazy(
  () => import('~/Public/Modules/Registration/AppSumoRegistration')
);

export default function ReactRoutes() {
  return (
    <BrowserRouter>
      <TrackPageViews />
      <Switch>
        <LazyRoute
          exact
          path={'/auth/register/appsumo'}
          component={AppSumoRegistration}
        />
        <LazyRoute path={['/auth/register']} component={SimpleCarousel} />
        <LazyRoute path={['/auth/invites']} component={LargeCarousel} />
        <LazyRoute exact path='/teams/:team?' component={Teams} />

        <LazyRoute path='/blog' component={Blog} />
      </Switch>
    </BrowserRouter>
  );
}
