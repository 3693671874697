export const palette = {
  aquaMarine: '#32d6bc',
  beige: '#f8f4ea',
  brightOrange: '#fc5a00',
  brownGrey: '#b4b4b4',
  brownGreyTwo: '#878787',
  brownishGrey: '#666666',
  camoGreen: '#38211d',
  coolGreen: '#2dd23b',
  darkGrey: '#403f3f',
  darkerGrey: '#323232',
  darkishPink: '#d0577d',
  fern: '#52ab5a',
  golden: '#f0c300',
  lightBlue: '#effdfe',
  lightGrey: '#d8d8d8',
  lightPeach: '#f9d5c1',
  lightPink: '#ffeff4',
  lightTeal: '#99e4d8',
  lightishGreen: '#6add73',
  parchment: '#fff1b2',
  robinSEgg: '#6ae4e8',
  rosyPink: '#ec799d',
  rust: '#963b09',
  lightYellow: '#f8f4ea',
  sunflowerYellow: '#ffd002',
  tealish: '#2dc2ab',
  tealishTwo: '#27b3c1',
  tealishThree: '#1f96a2',
  tealishFour: '#23d5c6',
  tealishFive: '#daf6f7',
  veryLightPink: '#f2f2f2',
  veryLightPinkTwo: '#e6e6e6',
  veryLightPinkThree: '#cdcdcd',
  white: '#f8f8f8',
};

const colors = {
  textDark: '#222222',
  borderLightGrey: '#cccccc',
  greyStandard: '#ddd',
  greyLight: '#faf8f0',
  greyLightAlt: '#fafafa',
  greyLightAlt2: '#aaaaaa',
  greyLightAlt3: '#b4b4b4',
  greyLightAlt4: '#fcfcfc',
  greyLightAlt5: '#ebebeb',
  greyLightAlt6: '#c0bebe',
  greyLightAlt7: '#d8d8d8',
  greyLightAlt8: '#e2e2e2',
  greyMedium: '#eeeeee',
  grey: '#c9c2bd',
  greyMediumDark: '#999999',
  greyDark: '#444444',
  greyDarkAlt: '#414141',
  greyDarkAlt2: '#666666',
  greyDarkAlt3: '#7d7c7c',
  greyDarkAlt4: '#6d7278',
  greyDarkAlt5: '#888787',
  greyDarkAlt6: '#8b959e',
  greyDarkAlt7: '#858585',
  formBgGrey: '#f5f5f5',
  contentMainBg: '#f2f2f2',
  textFormControl: '#666666',
  blue: '#27b3c1',
  blueHighlightAlt: '#d8f2f5',
  green: '#6add74',
  redLight: '#fdbd99',
  red: '#ff0e0e',
  redDark: '#bd460a',
  yellowLight: '#fff9e5',
  yellow: '#ffce00',
  orange: '#dc9300',
  planlockOverlay: 'rgba(255, 255, 255, 0.75)',
};

export const allColours = { ...palette, ...colors };

export default allColours;
