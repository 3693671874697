import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { get, upperFirst, camelCase, first } from 'lodash';

import {
  withDisplayName,
  getWrapperDisplayName,
  getWrappedComponentKey,
} from '~/App/Components/HOC/helpers';

const getDisplayName = (propName) => (Component, Wrapped) => {
  const componentKey = getWrappedComponentKey(Component);
  const wrappedName =
    get(Wrapped, componentKey) || Wrapped.displayName || Wrapped.name;

  return `${wrappedName}__Replaceable${upperFirst(camelCase(propName))}`;
};

function getComponents(props, propName, defaultComponents) {
  const components = props[propName];

  return {
    ...defaultComponents,
    ...components,
  };
}

/*
 * A higher-order component which provides a set of
 * default components (via the components prop) to the
 * underlying component.
 *
 * Replacement components can be passed to the components prop
 * to override the default components.
 *
 * Eg. A component `SomeComponent` has a child component node `Container`
 * which can be replaced at render time via the components prop
 *
 * <SomeComponent components={{Container: MyContainer}} />
 */
export default (defaultComponents, propName = 'components') =>
  withDisplayName((Wrapped) => {
    const ReplaceableComponents = (props) => {
      const wrappedProps = {
        ...props,
        [propName]: getComponents(props, propName, defaultComponents),
      };

      return <Wrapped {...wrappedProps} />;
    };

    ReplaceableComponents.defaultProps = {
      [propName]: {},
    };

    if (process.env.NODE_ENV !== 'production') {
      ReplaceableComponents.propTypes = {
        [propName]: PropTypes.shape(
          Object.keys(defaultComponents).reduce(
            (components, component) => ({
              ...components,
              [component]: PropTypes.any,
            }),
            {}
          )
        ),
      };
    }

    return ReplaceableComponents;
  }, getDisplayName(propName));
