import React, { useEffect } from 'react';
import useAnalytics from '~/react-hooks/use-analytics';

export function Blog() {
  const analytics = useAnalytics();

  function showIntercom() {
    analytics.plugins.intercom.update({
      hide_default_launcher: false,
    });
  }

  useEffect(() => {
    if (window.Intercom?.booted) {
      showIntercom();
    } else {
      analytics.on('ready:intercom', showIntercom);
    }
  }, []);

  return <></>;
}
