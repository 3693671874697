import axios from 'axios';
import { load } from 'recaptcha-v3';

import route from '../../utils/Route';
import Controller from '../../utils/Controller';
import { hideIntercomPopup } from '~/utils/IntercomPopup';

interface CreateSubscriberOptions {
  email: string;
  recaptcha: string | undefined;
  talon?: string | null;
}

function createSubscriber(
  url: string,
  { email, recaptcha, talon }: CreateSubscriberOptions
) {
  return axios
    .create({
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': document
          ?.querySelector('[name=csrf-token]')
          ?.getAttribute('content'),
      },
    })
    .post(url, { email, recaptcha, talon });
}

export default class SubscribeForm extends Controller {
  @route('blog/subscriber-form')
  blog() {
    hideIntercomPopup();

    const subForm = $('.create-subscriber-button');
    const subInput = $('.create-subscriber-input input');
    const subButton = $('.create-subscriber-title');
    const subHint = $('.create-subscriber-hint');

    const forms =
      document.querySelectorAll<HTMLFormElement>('.needs-validation');

    async function handleFormSubmit(event: Event, form: HTMLFormElement) {
      event.preventDefault();
      event.stopPropagation();

      if (form.checkValidity() === false) {
        subHint.removeClass('text-danger').text('Please fill in the form');
        return;
      }

      subForm.addClass('disabled');
      subInput.attr('disabled', 'disabled');
      subHint.removeClass('text-danger').text('Working..');
      subButton.html('<i class="fa fa-spinner fa-spin"></i>');
      form.classList.add('was-validated');

      const email = subInput.val() as string;
      const url = subForm.attr('action') as string;
      const talon = document.querySelector<HTMLInputElement>('#talon')?.value;

      const recaptcha = await load(window.Bonjoro.googleRecaptchaSiteKey ?? '');
      const token = await recaptcha.execute('blog');

      try {
        const result = await createSubscriber(url, {
          email,
          recaptcha: token,
          talon,
        });
        const { status, message } = result.data;

        if (status == 'failure') {
          throw message;
        } else {
          subForm.removeClass('disabled');
          subInput.removeAttr('disabled');
          subButton.text('Subscribe');
          subHint.addClass('text-success').text(message);
        }
      } catch (err) {
        subForm.removeClass('disabled');
        subInput.removeAttr('disabled');
        subButton.text('Subscribe');
        subHint.addClass('text-danger');

        if (err?.response?.status === 400) {
          subHint.text(err.response.data?.message);
        } else {
          subHint.text(err);
        }
      }
    }

    forms.forEach((form: HTMLFormElement) => {
      form.addEventListener(
        'submit',
        (event) => {
          handleFormSubmit(event, form);
        },
        false
      );
    });
  }
}
