import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { fromJS } from 'immutable';
import { combineReducers as combineReducersImmutable } from 'redux-immutable';
import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
  Store,
  Middleware,
  StoreEnhancer,
  ReducersMapObject,
} from '@reduxjs/toolkit';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

interface ConfigureStoreOptions<S> {
  initialState?: S;
  reducers: ReducersMapObject<S>;
  combineReducers?: typeof combineReducers;
  transformer?: (state: S) => unknown;
  enhancers?: StoreEnhancer[];
}

export const configureStore = <S = unknown>({
  initialState = undefined,
  reducers,
  combineReducers = combineReducersImmutable,
  transformer = fromJS,
  enhancers = [],
}: ConfigureStoreOptions<S>): Promise<Store<S>> => {
  const composeEnhancers =
    (process.env.NODE_ENV !== 'production'
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : null) || compose;

  const middewares = [thunk, ...(enhancers as unknown as Middleware[])];

  if (REDUX_LOGGER) {
    middewares.push(logger);
  }

  const createStoreArgs = [
    combineReducers(reducers),
    ...(initialState != null ? [transformer(initialState)] : []),
    composeEnhancers(applyMiddleware(...middewares)),
  ];

  return Promise.resolve((createStore as Function)(...createStoreArgs));
};
