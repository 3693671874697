const assetPath = process.env.ASSET_PATH ?? '/';
const assetsBaseUrl =
  assetPath === '/' ? `${location.origin}${assetPath}` : assetPath;

/**
 * Generate a URL for an asset path.
 *
 * @param {string} path Relative path to an asset;
 * @param [baseUrl] The URL to use as the basis
 * @returns {string} A URL pointing to the asset. Usually a local URL in development, and a CDN URL in production.
 */
export function assetUrl(
  path: string,
  baseUrl: string = assetsBaseUrl
): string {
  /**
   * simple string concatenation may not work, as the path can sometimes be a full url
   */
  return new URL(path.replace(/^\//, ''), baseUrl).toString();
}

/**
 * Generate a URL pointing to the Bonjoro S3 Bucket.
 * This is for assets that have been uploaded manually
 * to S3, or for uploaded user content.
 *
 * For assets included in the webpack bundle, use `assetUrl`.
 *
 * @param {string} path Relative path to an asset
 * @returns {string}
 * @see `assetUrl`
 */
export function s3Asset(path: string): string {
  const baseUrl = process.env.S3_CDN_URL;
  return assetUrl(
    `${baseUrl!.replace(/\/$/, '')}/${path.replace(/^\//, '')}`,
    baseUrl
  );
}
