export interface CrazyEggConfig {
  customScriptSource?: string;
}

const defaultScriptSrc = '//script.crazyegg.com/pages/scripts/0113/5102.js';

export default function CrazyEggPlugin(config: CrazyEggConfig) {
  return {
    name: 'crazyEgg',
    initialize() {
      const scriptElement = document.createElement('script');
      scriptElement.setAttribute(
        'src',
        config.customScriptSource ?? defaultScriptSrc
      );
      scriptElement.setAttribute('async', 'async');
      scriptElement.setAttribute('type', 'text/javascript');
      document.head.appendChild(scriptElement);
    },
  };
}
