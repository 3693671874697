import crossroads from 'crossroads';

import * as redux from './redux';

export default class Controller {
  public constructor(
    public router: typeof crossroads = crossroads,
    public configureStore: typeof redux.configureStore = redux.configureStore
  ) {}
}
