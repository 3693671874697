import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import colours from '~/utils/colours';

function widthToCss(width) {
  switch (typeof width) {
    case 'number':
      return `${width}px`;
    default:
      return width;
  }
}

export const theme = {
  bg: 'white',
  fg: colours.greyMediumDark,

  border: `1px solid ${colours.greyMedium}`,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',

  button: {
    borderRadius: '10px',
  },
};

const DropdownContainer = styled.div`
  border-radius: 4px;
  border: ${(props) => props.theme.border};
  box-shadow: ${(props) => props.theme.boxShadow};
  width: ${(props) => widthToCss(props.width)};
  background-color: ${(props) => props.theme.bg};
`;

DropdownContainer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
};

DropdownContainer.defaultProps = {
  theme,
};

export default DropdownContainer;
