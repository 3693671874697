import { partial } from 'lodash';
import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import Modal from '~/App/Components/Modal';
import { SubHeading } from '~/App/Components/Text';
import { TRANSITION_TIME_MS } from '~/App/Components/Modal/Constants';

const ContentContainer = styled(SubHeading)`
  margin-bottom: 5px;
`;

interface AlertProps {
  children: React.ReactNode;
  onClose: () => void;
}

const Alert = ({ children, onClose }: AlertProps) => {
  const [isOpen, setOpen] = useState(true);
  const onRequestClose = partial(setOpen, false);

  useEffect(() => {
    if (!isOpen && typeof onClose === 'function') onClose();
  }, [isOpen]);

  const modalProps = {
    isOpen,
    onRequestClose,
  };

  return (
    <Modal {...modalProps}>
      <ContentContainer>{children}</ContentContainer>
    </Modal>
  );
};

export const alertAsync = (message: React.ReactNode) => {
  const renderTarget = document.createElement('div');
  document.body.appendChild(renderTarget);

  return new Promise((resolve) => {
    function onClose() {
      setTimeout(
        partial(unmountComponentAtNode, renderTarget),
        TRANSITION_TIME_MS
      );
      resolve(false);
    }

    render(<Alert onClose={onClose}>{message}</Alert>, renderTarget);
  });
};
