export interface BonjoroJsConfig {
  appId: string;
  endpoint?: string;
  userHash?: string;
  customScriptSource?: string;
}

export interface BonjoroJsStatic {
  (
    method: 'create',
    appId: string,
    options?: {
      endpoint?: string;
    }
  ): void;

  (method: 'identify', email: string, traits?: Record<string, unknown>): void;

  instance?: {
    loaded: boolean;
  };
}

declare global {
  interface Window {
    bonjoro: BonjoroJsStatic;
  }
}

interface BaseParams {
  config: BonjoroJsConfig;
}

interface IdentifyParams extends BaseParams {
  payload: {
    traits: Record<string, unknown> & {
      email?: string;
    };
  };
}

const defaultScriptSrc = 'https://static.bonjoro.com/js/v1/bonjoro.js';

export default function BonjoroAnalyticsPlugin(config: BonjoroJsConfig) {
  return {
    name: 'bonjoro',
    config,

    initialize({ config }: BaseParams) {
      if (!config.appId) {
        throw new Error('No Bonjoro appId defined');
      }

      /* eslint-disable */
      // prettier-disable
      (() => {
        'use strict';
        (() => {
          var o;
          const n = function () {
            n.q.push(arguments);
          };
          (n.q = []),
            (null !== (o = window.bonjoro) && void 0 !== o) ||
              (window.bonjoro = n);
        })();
      })();
      /* eslint-enable */

      window.bonjoro('create', config.appId, {
        endpoint: config.endpoint,
      });

      const scriptElement = document.createElement('script');
      scriptElement.setAttribute(
        'src',
        config.customScriptSource ?? defaultScriptSrc
      );

      document.head.appendChild(scriptElement);
    },

    loaded() {
      return window.bonjoro?.instance?.loaded;
    },

    identify({ payload, config }: IdentifyParams) {
      const { traits } = payload;

      window.bonjoro('identify', traits.email as string, {
        userHash: config.userHash,
        ...traits,
      });
    },
  };
}
