import 'lazysizes';
import $ from 'jquery';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import swal from 'sweetalert';
import { render } from 'react-dom';
import { AnalyticsProvider } from 'use-analytics';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';

import './Routes';
import ReactRoutes from './ReactRoutes';
import analytics from '~/utils/Analytics';
import { reactQueryDefaultOptions } from '~/Config';
import { hideIntercomPopup } from '~/utils/IntercomPopup';

analytics.once('ready:intercom', () => {
  hideIntercomPopup();
});

function renderReactRoutes() {
  const queryClient = new QueryClient({
    defaultOptions: reactQueryDefaultOptions,
  });

  const target = document.createElement('div');
  document.body.appendChild(target);
  render(
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
      <AnalyticsProvider instance={analytics}>
        <ReactRoutes />
      </AnalyticsProvider>
    </QueryClientProvider>,
    target
  );
}

window.addEventListener('DOMContentLoaded', () => {
  $('.btn-email').on('click', (evt) => {
    evt.preventDefault();
    $('.form-email').removeClass('hidden');
    $('.oauth-providers').addClass('hidden');
    $('input[name="email"]').focus();
  });

  renderReactRoutes();
});

swal.setDefaults({
  confirmButtonColor: '#FC5A00',
});

setTimeout(() => {
  const flashMessage = document.getElementsByClassName('flash-message');

  if (flashMessage.length) {
    $(flashMessage).slideUp();
  }
}, 5000);
